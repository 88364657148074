import './css/App.css';
import Slideshow from './Slideshow';
import About from './About';
import Update from './Update';
import Service from './Service';
import Totop from './Totop';
import { isMobile } from 'react-device-detect'
import SignatureProduct from './SignatureProduct';

function App() {
  return (
      <section className='app-top'>
        <Slideshow/>
        <About/>
        <Service/>
        <Update/>
        <SignatureProduct/>
        { !isMobile ? <Totop/> : null}
      </section>
    
  );
}

export default App;
