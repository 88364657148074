import f1i1 from '../img/包胶0字扣/012B（椭圆扣）.jpg'
import f1i2 from '../img/包胶0字扣/014B(带牙椭圆扣）.jpg'
import f1i3 from '../img/包胶0字扣/018F.jpg'
import f1i4 from '../img/包胶0字扣/N10(吊袜扣）.jpg'
import f1i5 from '../img/包胶0字扣/三角形扣.jpg'
import f1i6 from '../img/包胶0字扣/吊袜扣其他款型.jpg'
import f1i7 from '../img/包胶0字扣/环勾扣.jpg'
import f1i8 from '../img/包胶0字扣/花型0扣.jpg'

import f2i1 from '../img/包胶8字扣/807B.jpg'
import f2i2 from '../img/包胶8字扣/810DA.jpg'
import f2i3 from '../img/包胶8字扣/812H.jpg'
import f2i4 from '../img/包胶8字扣/830B.jpg'
import f2i5 from '../img/包胶8字扣/有牙8扣.jpg'

import f3i1 from '../img/包胶9字扣/905E.jpg'
import f3i2 from '../img/包胶9字扣/912R.jpg'
import f3i3 from '../img/包胶9字扣/916E.jpg'
import f3i4 from '../img/包胶9字扣/920E.jpg'
import f3i5 from '../img/包胶9字扣/930.jpg'
import f3i6 from '../img/包胶9字扣/G908E(15).jpg'
import f3i7 from '../img/包胶9字扣/G912A.jpg'
import f3i8 from '../img/包胶9字扣/普通款9字扣.jpg'

import f4i1 from '../img/合金扣/9字扣.jpg'
import f4i2 from '../img/合金扣/圈扣.jpg'
import f4i3 from '../img/合金扣/合金扣1.jpg'

import f5i1 from '../img/特殊形状扣/812R.jpg'
import f5i2 from '../img/特殊形状扣/VF23.jpg'

import f6i1 from '../img/背扣/背扣勾.jpg'
import f6i2 from '../img/背扣/背扣勾2.jpg'

import f7i1 from '../img/钢圈/包胶钢圈.jpg'
import f7i2 from '../img/钢圈/记忆钢圈.jpg'

import f8i1 from '../img/钢托/V型钢托.jpg'
import f8i2 from '../img/钢托/包胶钢圈.jpg'
import f8i3 from '../img/钢托/水滴型钢托.jpg'
import f8i4 from '../img/钢托/特殊型钢托.jpg'

import f9i1 from '../img/钢骨/不锈钢钢骨.jpg'
import f9i2 from '../img/钢骨/镀锌钢骨.jpg'

export const productcollection = [
    {
        id: 1,
        collections: [
            {
                id: 11,
                img: f1i1,
                alt: "012B（椭圆扣）"
            },
            {
                id: 12,
                img: f1i2,
                alt: "014B(带牙椭圆扣）"
            },
            {
                id: 13,
                img: f1i3,
                alt: "018F"
            },
            {
                id: 14,
                img: f1i4,
                alt: "N10(吊袜扣）"
            },
            {
                id: 15,
                img: f1i5,
                alt: "三角形扣"
            },
            {
                id: 16,
                img: f1i6,
                alt: "吊袜扣其他款型"
            },
            {
                id: 17,
                img: f1i7,
                alt: "环勾扣"
            },
            {
                id: 18,
                img: f1i8,
                alt: "花型0扣"
            }
        ],
        alt: "包胶0字扣"
    },
    {
        id: 2,
        collections: [
            {
                id: 21,
                img: f2i1,
                alt: "807B"
            },
            {
                id: 22,
                img: f2i2,
                alt: "810DA"
            },
            {
                id: 23,
                img: f2i3,
                alt: "812H"
            },
            {
                id: 24,
                img: f2i4,
                alt: "830B"
            },
            {
                id: 25,
                img: f2i5,
                alt: "有牙8扣"
            }
        ],
        alt: "包胶8字扣"
    },
    {
        id: 3,
        collections: [
            {
                id: 31,
                img: f3i1,
                alt: "905E"
            },
            {
                id: 32,
                img: f3i2,
                alt: "912R"
            },
            {
                id: 33,
                img: f3i3,
                alt: "916E"
            },
            {
                id: 34,
                img: f3i4,
                alt: "920E"
            },
            {
                id: 35,
                img: f3i5,
                alt: "930"
            },
            {
                id: 36,
                img: f3i6,
                alt: "G908E(15)"
            },
            {
                id: 37,
                img: f3i7,
                alt: "G912A"
            },
            {
                id: 38,
                img: f3i8,
                alt: "普通款9字扣"
            }
        ],
        alt: "包胶9字扣"
    },
    {
        id: 4,
        collections: [
            {
                id: 41,
                img: f4i1,
                alt: "9字扣"
            },
            {
                id: 42,
                img: f4i2,
                alt: "圈扣"
            },
            {
                id: 43,
                img: f4i3,
                alt: "合金扣1"
            }
        ],
        alt: "合金扣"
    },
    {
        id: 5,
        collections: [
            {
                id: 51,
                img: f5i1,
                alt: "812R"
            },
            {
                id: 52,
                img: f5i2,
                alt: "VF23"
            }
        ],
        alt: "特殊形状扣"
    },
    {
        id: 6,
        collections: [
            {
                id: 61,
                img: f6i1,
                alt: "背扣勾"
            },
            {
                id: 62,
                img: f6i2,
                alt: "背扣勾2"
            }
        ],
        alt: "背扣"
    },
    {
        id: 7,
        collections: [
            {
                id: 71,
                img: f7i1,
                alt: "包胶钢圈"
            },
            {
                id: 72,
                img: f7i2,
                alt: "记忆钢圈"
            }
        ],
        alt: "钢圈"
    },
    {
        id: 8,
        collections: [
            {
                id: 81,
                img: f8i1,
                alt: "V型钢托"
            },
            {
                id: 82,
                img: f8i2,
                alt: "包胶钢圈"
            },
            {
                id: 83,
                img: f8i3,
                alt: "水滴型钢托"
            },
            {
                id: 84,
                img: f8i4,
                alt: "特殊型钢托"
            }
        ],
        alt: "钢托"
    },
    {
        id: 9,
        collections: [
            {
                id: 91,
                img: f9i1,
                alt: "不锈钢钢骨"
            },
            {
                id: 92,
                img: f9i2,
                alt: "镀锌钢骨"
            }
        ],
        alt: "钢骨"
    }
]