import Productitem from "./Productitem";
import './css/SignatureList.css'
import { signatureproductcollection } from "./staticinfo/signatureproductcollection";
import { Link } from "react-router-dom";

const SignatureList = () => {
    return <>
        <section className="signaturelist">
            { signatureproductcollection.map(item => {
                return (
                    <div className="productitem" key={item.id}>
                        <Link to={"/productlist"}>
                            <Productitem p = {item}/>
                        </Link>
                    </div>
                )
            })}
        </section>
    </>
}

export default SignatureList;