import { FaFacebookSquare, FaLinkedin } from 'react-icons/fa'
import { IoLogoWechat } from 'react-icons/io5'
import { SiTencentqq } from 'react-icons/si'
import qq_qrcode from '../img/qq_qrcode.png'
import wechat_qrcode from '../img/wechat_qrcode.png'

export const socialinfo = [
    {
        id: 1,
        path: "https://www.linkedin.com/company/defa-underwear-accessories-co-ltd/?viewAsMember=true",
        icon: <FaLinkedin/>
    },
    {
        id: 2,
        path: "https://www.facebook.com/profile.php?id=100075414139164",
        icon: <FaFacebookSquare/>
    },
    {
        id: 3,
        path: wechat_qrcode,
        icon: <IoLogoWechat/>
    },
    {
        id: 4,
        path: qq_qrcode,
        icon: <SiTencentqq/>
    }
]