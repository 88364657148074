import { useParams } from "react-router-dom";
import './css/Product.css'
import Productitem from "./Productitem";
import { productcollection } from "./staticinfo/productcollection";

const Product = () => {
    const { pid } = useParams();

    return(
        <>
            <h2>{productcollection[pid - 1].alt}</h2>
            <section className="product">
                {productcollection[pid - 1].collections.map((p) => {
                    return (
                        <Productitem key={p.id} p = {p}/>
                    )
                })}
            </section>
        </>
    )
}

export default Product;