import './css/Update.css'
import { Link } from 'react-router-dom';
import { CgAddR } from 'react-icons/cg'

const Update = () => {
    return(
        <section id="update" className="update">
            <h2>最新动态/Updates</h2>
            <div className='updatePosts'>
                <div className='post'>
                    <div className='postday'>
                        <p>26</p>
                        <p>2022-2</p>
                    </div>
                    <div className='postcontext'>
                        <p>招聘⻋间员⼯多名</p>
                        <p>3000~5000 招多名生产工</p>
                    </div>
                    <Link to={"/post1"}>
                        <CgAddR className='morebtn'/>
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default Update;