import './css/ProductCategory.css'
import { CgEnter } from 'react-icons/cg'
import { Link } from 'react-router-dom'

const ProductCategory = ({ picInfo }) => {
    const picFirst = picInfo.collections[0];

    return (
        <div className='productinfo'>
            <li key={picInfo.id}>
                <img src={picFirst.img} alt={picFirst.alt}/>
            </li>
            <div className='subinfo'>
                <p>{picInfo.alt}</p>
                
                <Link to={`/productlist/${picInfo.id}`}>
                    <button>進入相簿<CgEnter className='enterbtn'/></button>
                </Link>
            </div>
            <hr></hr>
        </div>
    )
}

export default ProductCategory;