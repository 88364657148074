import logo from './img/logo_bg_rm.png'
import './css/Navbar.css'
import { FiMenu } from 'react-icons/fi'
import { GrFormClose } from 'react-icons/gr'
import { useEffect, useContext, useCallback } from 'react'
import { Link } from 'react-router-dom'
import ScrollContext from './ScrollContext'

const Navbar = () => {
    const {directLocation, show, setShow} = useContext(ScrollContext);

    const updateScroll = useCallback(() => {
        const disfromtop = window.pageYOffset;
        
        if((show && disfromtop > window.innerHeight / 3) || (window.innerWidth > 700))
        {
            setShow(false);
        }
    },[show, setShow])

    useEffect(() => {
        window.addEventListener("scroll", updateScroll);
        return (() => window.removeEventListener("scroll", updateScroll))
    }, [updateScroll])

    return(
        <section className={'navbar-top'}>
            <div className='header'>
                <a href='/'><img src={logo} alt="logo"/></a>
                <button className='menu' onClick={() => setShow(!show)}>
                    { show ? <GrFormClose/> : <FiMenu/> }
                </button>
            </div>
            
            <div className={ show ? 'menu-list show' : 'menu-list' }>
                <ul className={'navlist'}>
                    <li id="aboutus_N" onClick={e => directLocation(e)}>关于我们</li>
                    <li id="product_list"><Link to={"/productlist"} onClick={e => directLocation(e)}>产品展示</Link></li>
                    <li id="update_N" onClick={e => directLocation(e)}>最新动态</li>
                    <li id="contactus_N" onClick={e => directLocation(e)}>联系我们</li>
                </ul>
            </div>
        </section>
    )
}

export default Navbar;