import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ScrollContext from "./ScrollContext";

const ScrollInfo = ({children}) => {
    const [selectId, setSelectId] = useState("");
    const [scrollHeight, setScrollHeight] = useState(0);
    const [navigateToHome, setNavigateToHome] = useState(true);
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    const directLocation = (e) => {
      const location = window.location.pathname;
      if(location === "/productlist" && e.target.id === "product_list")
      {
        if(window.innerWidth < 700)
          setShow(false);
      }
      else if(location !== "/")
      {
        navigate("/")
        setNavigateToHome(true);
        if(window.innerWidth < 700)
          setShow(!show);
        setId(e);
      }
      else
      {
        if(window.innerWidth < 700)
          setShow(!show);
        setNavigateToHome(false);
        setId(e);
      }
    }

    const setId=(e)=>{
      let id = e.target.id;
      if(id === "aboutus_N")
        setSelectId("aboutus")
      else if(id === "update_N")
        setSelectId("update")
      else if(id === "contactus_N")
        setSelectId("contactus")
      else
        setSelectId("")
    }

    const setHeight = useCallback(() => {
      if(selectId)
      {
        let sectionDistanceFromTop = document.getElementById(selectId);
        sectionDistanceFromTop = sectionDistanceFromTop.offsetTop;
        let navbarH = document.getElementsByClassName("navbar-top")[0];
        navbarH = navbarH.clientHeight;
        setScrollHeight(sectionDistanceFromTop - navbarH - 20);
      }
    },[selectId]);

    useEffect(() => {
        setHeight();
    },[selectId, setHeight, navigateToHome])
  
    useEffect(() => {
      window.scrollTo(0, scrollHeight);
    },[scrollHeight])

    return (
        <ScrollContext.Provider 
          value={{directLocation, show, setShow}}
        >
          {children}
        </ScrollContext.Provider>
    )
  }

  export default ScrollInfo