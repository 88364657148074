import './css/Productitem.css'

const Productitem = ({ p }) => {
    return (
        <div className="productdetail">
            <img src={p.img} alt={p.alt}/>
            <p>{p.alt}</p>
        </div>
    )
}

export default Productitem;