import postimg from './img/postImg.PNG'
import './css/UpdatePost.css'

const UpdatePost = () => {
    return <>
        <section className="updatepostpage">
            <p>2月26日 - 招聘车间员工多名</p>
            <div className="toppostpage">
                <h1>招聘</h1>
                <p>佛山德法内衣配件有限公司</p>
                <div className='bottompostpage'>
                    <img src={postimg} alt="postimg"/>
                    <div className='detail'>
                        <p>招聘车间员工多名</p>
                        <p>- 保底 3000至5000元/月</p>
                        <p>员工福利</p>
                        <ul>
                            <li>
                                免费提供标准宿舍和中晚餐，早餐自理；（内设空调，热水器，独立卫生间）
                            </li>
                            <li>
                                逢周日，法定节日休息（除特殊情况）
                            </li>
                            <li>
                                出粮准时（每月全勤奖100元）
                            </li>
                            <li>
                                夏季有高温补贴
                            </li>
                            <li>
                                晚上超时加班有夜餐补贴
                            </li>
                            <li>
                                每年春节享有路费补助
                            </li>
                            <li>
                                员工入职满半年以上，年终享有工龄补助
                            </li>
                            <li>
                                （满一年可享有360元，满两年可享720元，依此类推，最高达3600元/年）
                            </li>
                            <li>
                                公司每年组织一次秋季旅游等福利待遇
                            </li>
                            <li>
                                后勤岗位试用期满后带有绩效待遇
                            </li>
                        </ul>
                        <p>联系：周小姐 0757-85602379 / 13326739485</p>
                        <p>地址：佛山市南海区里水镇沙涌陈合院工业区13号</p>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default UpdatePost;