import React from "react";
import slide1 from "./img/slide1.jpg"
import slide2 from "./img/slide2.jpg"
import slide3 from "./img/slide3.png"
import './css/Slideshow.css'
import { useSwipeable } from "react-swipeable";
import { useState, useEffect} from "react";
import Indicator from "./Indicator";

const Slideshow = () => {
    const [slideid, setSlideid] = useState(1)

    const updateIDauto = (id) => {
        if(id >= 1 && id < 3)
            setSlideid(slideid => slideid + 1);
        else
            setSlideid(1)
    }
    useEffect(() => {
        let r = setInterval(() => {
                updateIDauto(slideid);
            }, 5000)

        return () => clearInterval(r);
    },[slideid])

    const updateid = (id) => {
        updateIDauto(id);
    }

    const clickIn = (id) => {
        setSlideid(id)
    }

    const swipehandler = useSwipeable({
        onSwipedLeft: (eventData) => updateid(slideid),
        onSwipedRight: (eventData) => updateid(slideid)
    })

    return(
        <section>
            <div {...swipehandler}>
                <div className="slideshowwrapper">
                    {
                        slideid === 1 ?
                        <img className="slide" src={slide1} alt={slide1}/> :
                        slideid === 2 ?
                        <img className="slide" src={slide2} alt={slide2}/> :
                        <img className="slide" src={slide3} alt={slide3}/> 
                    }
                </div>
                <Indicator clickIn={clickIn}/>
            </div>
        </section>
    )
}

export default Slideshow;