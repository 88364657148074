import sp1 from '../img/signatureProduct/sp1.jpg'
import sp2 from '../img/signatureProduct/sp2.jpg'
import sp3 from '../img/signatureProduct/sp3.jpg'
import sp4 from '../img/signatureProduct/sp4.jpg'
import sp5 from '../img/signatureProduct/sp5.jpg'
import sp6 from '../img/signatureProduct/sp6.jpg'

export const signatureproductcollection = [
    {
        id: 1,
        img: sp1,
        alt: "环勾扣",
    },
    {
        id: 2,
        img: sp2,
        alt: "花型0扣",
    },
    {
        id: 3,
        img: sp3,
        alt: "记忆钢圈",
    },
    {
        id: 4,
        img: sp4,
        alt: "背扣勾2",
    },
    {
        id: 5,
        img: sp5,
        alt: "包胶钢圈",
    },
    {
        id: 6,
        img: sp6,
        alt: "合金扣1",
    }
]