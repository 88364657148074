import './css/SignatureProduct.css'
import SignatureList from './SignatureList'

const SignatureProduct = () => {
    return <>
        <section className="productCapture">
            <h2>产品展示/Product Display</h2>
            <SignatureList/>
        </section>
    </>
}

export default SignatureProduct;