import './css/Service.css'
import sl1 from './img/特写图/内衣钢圈制作.jpg'
import sl2 from './img/特写图/内衣调整扣制作.jpg'
import sl3 from './img/特写图/内衣鱼鳞骨制作.jpg'
import sl4 from './img/特写图/内衣包胶扣制作.jpg'
import { GrContact } from 'react-icons/gr'

const Service = () => {
    return(
        <section id="service">
            <h2>服务项目/Services</h2>
            <div className="serviceLists">
                <div key="service1">
                    <img src={sl1} alt="service1 - 内衣钢圈制作"/>
                    <p>内衣钢圈制作</p>
                </div>
                <div key="service2">
                    <img src={sl2} alt="service2 - 内衣调整扣制作"/>
                    <p>内衣调整扣制作</p>
                </div>
                <div key="service3">
                    <img src={sl3} alt="service3 - 内衣鱼鳞骨制作"/>
                    <p>内衣鱼鳞骨制作</p>
                </div>
                <div key="service4">
                    <img src={sl4} alt="service4 - 内衣包胶扣制作"/>
                    <p>内衣包胶扣制作</p>
                </div>
            </div>
            <hr></hr>
            <a href='#contactus'>
                <div className='contactmore'>
                    <GrContact/>
                    <p>了解更多請與我們聯繫</p>
                    <p>Contact Us for More Information and Services</p>
                </div>
            </a>
        </section>
    )
}

export default Service;