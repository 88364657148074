import './css/Popup.css'
import { FaRegHandPointRight } from 'react-icons/fa'
import { AiFillCloseSquare } from 'react-icons/ai'

const Popup = ({ closeModel }) => {
    return (
        <section className='popupmodel'>
            <div className='notice'>
                <AiFillCloseSquare onClick={closeModel} className='closebtn'/>
                <h2>欢迎/ WELCOME</h2>
                <div className='options'>
                    <FaRegHandPointRight className='clickbtn'/>
                    <span id="offical" onClick={closeModel}>Offical Web / 官网</span>
                    <br/>
                    <FaRegHandPointRight className='clickbtn'/>
                    <a href='https://shop1436511183425.1688.com/?spm=a2615.2177701.autotrace-topNav.1.5603608fQY5ioT&fbclid=IwAR0bxmQac-7VzNLL0HRbKhxlmrVjc-OUlvbWMdT2yaHAJnn_MUx8PHN8ygo' target="_blank" rel="noopener noreferrer">阿里巴巴(1688)/Alibaba China</a>
                    <br/>
                    <FaRegHandPointRight className='clickbtn'/>
                    <a href='https://fsdefa.en.made-in-china.com/?fbclid=IwAR1fTxb7caCgLBj2g4LsUvodWOW0HxioWFtXXNx3LqagVJXg2_ZDSeGfgAY' target="_blank" rel="noopener noreferrer">中国制造/ Made-In-China（International Entrance）</a>
                </div>
            </div>
        </section>
    )
}

export default Popup;