import UpdatePost from './UpdatePost';
import Popup from './Popup';
import ScrollToTop from './ScrollToTop';
import Productlist from './Productlist'
import Product from './Product'
import Error from './Error';
import Navbar from './Navbar'
import App from './App';
import { Routes, Route } from 'react-router-dom'
import { useState } from 'react';

const BodyPart = () => {
    const [close, setClose] = useState(false);

    const closeModel = () =>{
        setClose(true)
    }

    if(close)
    {
        return (
            <>
                <Navbar/>
                <ScrollToTop>
                <Routes>
                    <Route path="/" element={<App />}/>
                    <Route path="/productlist" element={<Productlist />}/>
                    <Route path="/productlist/:pid" element={<Product/>}/>
                    <Route path="/:postid" element={<UpdatePost/>}/>
                    <Route path="*" element={<Error/>}/>
                </Routes>
                </ScrollToTop>
            </>
        )
    }
    else
    {
        return (
            <>
                <Popup closeModel={closeModel}/>
                <Navbar/>
                <ScrollToTop>
                <Routes>
                    <Route path="/" element={<App />}/>
                    <Route path="/productlist" element={<Productlist />}/>
                    <Route path="/productlist/:pid" element={<Product/>}/>
                    <Route path="/:postid" element={<UpdatePost/>}/>
                    <Route path="*" element={<Error/>}/>
                </Routes>
                </ScrollToTop>
            </>
        )
    }
    
}

export default BodyPart;