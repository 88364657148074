import { useState, useEffect } from 'react'
import { BsFillArrowUpSquareFill } from 'react-icons/bs'
import './css/Totop.css'

const Totop = () => {
    const [showtopbtn, settopbtn] = useState(false);

    const showBtn = () => {
        const disfromtop = window.pageYOffset;
        if (disfromtop > 450){
          settopbtn(true)
        } 
        else{
          settopbtn(false)
        }
      };

    const scrollup = () => {
        window.scrollTo({
            top: 0
        })
    }

    useEffect(() => {
      window.addEventListener("scroll", showBtn);
      return( () => window.removeEventListener("scroll", showBtn))
    })

    return (
        <section className="top" onClick={() => scrollup()}>
            { showtopbtn && <BsFillArrowUpSquareFill className="topbtn"/>}
        </section>
    )
}

export default Totop;