import React from "react";
import { useNavigate } from "react-router-dom";

const Error = () => {
    const navigate = useNavigate();

    return <>
        <h1>Page not found</h1>
        <button onClick={() => navigate("/")}>BACK TO HOME</button>
    </>
}

export default Error;