import './css/Footer.css'
import { socialinfo } from './staticinfo/mediainfo'

const Footer = () => {
    return(
        <section id="contactus" className='footer-block'>
            <div className='info'>
                <p>联系地址：<a href='https://www.google.com/maps/dir//%E4%B8%AD%E5%9C%8B%E5%B9%BF%E4%B8%9C%E7%9C%81%E4%BD%9B%E5%B1%B1%E5%B8%82%E5%8D%97%E6%B5%B7%E5%8C%BA%E9%99%88%E5%90%88%E9%99%A2%E5%B7%A5%E4%B8%9A%E5%A4%A7%E9%81%9313%E5%8F%B7+%E9%82%AE%E6%94%BF%E7%BC%96%E7%A0%81:+528248/@23.1482849,113.1672813,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x3402f7982236d509:0x37556d943ca7bf9d!2m2!1d113.16947!2d23.14828!3e0'>
                中国广东省佛山市南海区里水镇沙涌陈合院工业大道13号德法内衣配件有限公司</a>
                </p>
                <p>联系电话：<a href="tel:+8613326752709">+86 13326752709</a></p>
                <p>工厂电话：<a href="tel:+86075785602379">+86 0757-85602379</a></p>
                <p>Fax：+86 0757-85602375</p>
                <p>电子邮件：<a href="mailto:defa126@126.com">defa126@126.com</a>（国内）或 <a href="mailto:Foshan.defa@gmail.com"> Foshan.defa@gmail.com</a> (国外/International)</p>
            </div>
            <hr className='divider'></hr>
            <div className='social'>
                <ul>
                    {socialinfo.map((social) => {
                        return(
                            <li key={social.id}><a href={social.path} target="_blank" rel="noopener noreferrer">{social.icon}</a></li>
                        )
                    })}
                </ul>
            </div>
        </section>
    )
}

export default Footer;