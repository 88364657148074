import { BsCircle } from'react-icons/bs'
import './css/Indicator.css'

const Indicator = ({ clickIn }) => {
    return(
        <>
            <BsCircle className="indi" onClick={() => clickIn(1)}/>
            <BsCircle className="indi" onClick={() => clickIn(2)}/>
            <BsCircle className="indi" onClick={() => clickIn(3)}/>
        </>
    )
}

export default Indicator;