import './css/Productlist.css'
import Totop from './Totop'
import ProductCategory from './ProductCategory'
import { productcollection } from './staticinfo/productcollection'
import { isMobile } from 'react-device-detect'

const Productlist = () => {
    return(
        <>
            <section className="products">
                <h2>产品图库</h2>
                <ul className='listproduct'>
                    { productcollection.map((product) => {
                        return (
                            <ProductCategory key={product.id} picInfo = {product}/>
                        )
                    })}
                </ul>
            </section>
            { !isMobile ? <Totop/> : null }
        </>
    )
}

export default Productlist;