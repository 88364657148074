import "./css/About.css"

const About = () => {
    const directToInfo = () => {
        window.open("https://fsdefa.en.made-in-china.com/?fbclid=IwAR3QKnye5t17hp-PFATjt5KkTPXboeW1RIfMPxwhHVkWUKyMJkIz-CWsLp0");
    }

    return(
        <section className="about-center" id="aboutus">
            <h2>关于我们/About us</h2>
            <article>
                佛⼭德法内⾐配件有限公司，于2005年创⽴，是⼀家集⽣产、销售、研发于⼀
                体的现代化内⾐配件公司。历经⼗多年的发展，公司现拥有100多名优秀技术⼈
                才，半⾃动化⽣产流⽔线，是内⾐配件⾏业⻣⼲公司之⼀。本公司主要⽣产胸围
                不锈钢圈、记忆钢圈、包胶钢圈、尼⻰包胶调节扣、合⾦包胶调节扣、背扣、花
                仔、吊坠、和饰物等⼀系列的胸围配件，并且提供专业内⾐配件染⾊服务。公司
                所有产品均符合OEKO-TEX 100标淮。
            </article>
            <article>
                公司始终本着“竭尽服务、品质第⼀”的宗旨，着眼于市场需求，⾼瞻远瞩，凭借
                多年⾟勤耕耘和守信经营，赢得了业界和客户的⼀致好评和信任。我们会继续努
                ⼒，积极开拓，不断提升技术及管理⽔平，为⼴⼤客户提供更加优质的产品和服
                务。
            </article>
            <article>
                Foshan Defa Underwear Fittings Co., Ltd, founded in 2005, is one of the
                modern underwear accessories corporations which incorporates production,
                sales, and research and development. After more than ten years of
                development, the company has a qualitative leap, with more than 100 talents,
                a semi-automatic production line, and is one of the outstanding corporations
                in the underwear accessories industry.
            </article>
            <article>
                The company mainly produces underwear accessories such as stainless steel
                bra wires, memory-metal bra wires, plastic bra wires, bra adjusters, alloy
                metal adjusters, bra bows, pendants, ornaments, etc. Additionally, we
                provide professional dyeing services for different types of underwear
                accessories products. The company uses a high standard of Switzerland
                (OEKO-TEX100) inspection to monitor and ensure all the products comply
                with the standard.
            </article>
            <article>
                The company has always committed to "Best service, Quality first" for our
                customers while focusing on market demand and being forward-looking.
                Years of hard work and trustworthy management have earned praise and
                trust from the industry and customers. Our company will actively forge ahead
                to improve our technology and management, and provide our customers with
                better quality products and services!
            </article>
            <button className="btn-info" onClick={directToInfo}>More Info</button>
        </section>
    )
}

export default About;